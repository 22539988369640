<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">{{ marshalId ? 'Manage' : 'Add' }} Marshal Management</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<template>
				<b-breadcrumb :items="items"></b-breadcrumb>
			</template>
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="row" v-else>
				<div class="col-12  border border-white bg-white ">
					<div class="form-group">
						<!-- <p class="m-3">Profile Image</p> -->
						<div class="avatar avatar-xl">
							<img :src="imagePreview" class="avatar-img rounded-circle">
						</div>
						<p class="form-label mb-0 highlight" @click="$refs.imgUpload.click()">{{ this.form.image ? 'Change' : 'Upload' }} Image</p>
						<input
							type="file" ref="imgUpload" @change="uploadFile" accept=".png, .jpg, .jpeg"
							class="hidden d-block m-0 p-0" style="height: 0" />
					</div>
					<div class="card-body">
						<div
							class="alert alert-danger alert-dismissible fade show"
							role="alert"
							v-if="errorMessage"
							>
							{{ errorMessage }}
						</div>
						<form @submit.prevent="manageMarshal">
							<div class="row mt-4">
								<div class="col-12 col-md-6 bg-grey-100 rounded h-8 w-8">

								</div>
							</div>
							<div class="row mt-4">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label class="form-label"> First name </label>
										<input
											v-model.trim="marshal.fname"
											required
											type="text"
											class="form-control"
											/>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label class="form-label"> Last name </label>
										<input
											v-model.trim="marshal.lname"
											required
											type="text"
											class="form-control"
											/>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label class="form-label"> Phone Number </label>
										<input
											v-model.trim="marshal.phone"
											required
											type="text"
											class="form-control mb-3"
											placeholder="(___)___-____"
											inputmode="text"
											/>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form group">
										<label class="form-label">Gender</label>
										<input
											v-model.trim="marshal.gender"
											required
											type="text"
											class="form-control mb-3"
											inputmode="text"
											/>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label class="mb-1"> Email Address </label>
										<input
											v-model.trim="marshal.email"
											required
											type="email"
											class="form-control"
											/>
									</div>
								</div>
								<div class="col-12 col-md-6" v-if="!marshalId">
									<div class="form-group">
										<label class="mb-1"> Address (optional)</label>
										<input
											placeholder="enter your address"
											type="text"
											class="form-control"
											/>
									</div>
								</div>
							</div>
							<div class="d-flex justify-content-end">
								<button
									type="submit"
									class="btn bg-white border border-dark px-5 mt-4 mr-4"
									>Cancel
								</button>
								<button
									type="submit"
									class="btn btn-primary mt-4"
									:disabled="processing"
									>
									{{
										processing
											? 'Processing...'
											: `${marshalId ? 'Update' : 'Add'} Marshal`
									}}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {

  props: ['marshalId'],
  data () {
    return {
      marshal: {
        fname: '',
        lname: '',
        phone: '',
        email: ''
      },
      form: {
        image: null
      },
      items: [
        {
          text: 'Marshal Management',
          href: '#'
        },
        {
          text: 'Add Marshal',
          href: '#'
        }
      ],
      processing: false,
      errorProcessing: false,
      errorMessage: '',
      loading: false,
      errorLoading: false,
      mainProps: { blank: true, blankColor: '#000', width: 75, height: 75, class: 'm1' }
    }
  },
  created () {
    if (this.marshalId) {
      this.fetchMarshal()
    }
  },
  methods: {
    uploadFile () {
      this.file = this.$refs.imgUpload.files[0]
      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        this.form.image = this.newAttachment = event.target.result
      })
      reader.readAsDataURL(this.file)
    },
    fetchMarshal () {
      this.loading = true
      this.errorLoading = false

      this.axios
        .get(`/v1/bus-marshals/${this.marshalId}`)
        .then((res) => {
          this.marshal = { ...res.data }
        })
        .catch(() => {
          this.errorLoading = true
        })
        .finally(() => (this.loading = false))
    },
    manageMarshal () {
      this.processing = true
      this.errorProcessing = false

      this.errorMessage = ''

      this.axios({
        method: this.marshalId ? 'PATCH' : 'POST',
        url: this.marshalId ? `/v1/bus-marshals/${this.marshalId}` : '/v1/bus-marshals/',
        data: {
          fname: this.marshal.fname,
          lname: this.marshal.lname,
          phone: this.marshal.phone,
          email: this.marshal.email,
          password: '12345'

        }
      })
        .then((res) => {
          this.$swal({
            icon: 'success',
            title: `Marshal ${this.MarshalId ? 'Updated' : 'Added'}`,
            text: `The Marshal "${this.marshal.fname}" has been ${
                this.marshalId ? 'updated' : 'added'
              } succesfully`,
            showCloseButton: true
          }).then(() => {
            this.$router.push({
              name: 'ShowMarshal',
              params: { marshalId: res.data.id }
            })
          })
        })
        .catch((e) => {
          this.errorProcessing = true

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg

          this.$swal({
            icon: 'error',
            text: 'An error occured, please try again',
            showCloseButton: true
          })
        })
        .finally(() => (this.processing = false))
    }
  },
  computed: {
    imagePreview () {
      return this.form.image ? this.form.image : require('@/assets/img/icons/camera.svg')
    }
  }
}
</script>
  <style scoped>

  </style>
